import type { Span } from "@sentry/react";
import { startSpan } from "./init";

export function wrapWithPerformanceTracking<Args extends unknown[], ReturnType>(
  fn: (...args: Args) => Promise<ReturnType>,
  metricName: string,
  attributes?: Record<string, string>
) {
  return async function wrapped(...args: Args): Promise<ReturnType> {
    return new Promise(function (resolve, reject) {
      startSpan(
        { op: "function", name: metricName, attributes },
        async (span: Span) => {
          try {
            const result = await fn(...args);
            span.setStatus("ok");
            resolve(result);
            // eslint-disable-next-line no-catch-all/no-catch-all
          } catch (error) {
            span.setStatus("error");
            reject(error);
          } finally {
            span.end();
          }
        }
      );
    });
  };
}
